import { useState } from 'react';
import {NavLink,Link} from 'react-router-dom'
// import {Switch as SwitchMode} from '@mui/material';
import logo from '../assets/images/logo.svg'
import { Modal, Button } from "react-bootstrap"
import { HashLink } from 'react-router-hash-link';
import pakistan from '../assets/images/pakistan.png'
import germany from '../assets/images/germany.png'
import whatsapp from '../assets/images/whatsapp.png'
import closeIco from '../assets/images/closeIco.svg'

export const Navbar = ({handleShow}) => {

  const [showContact, setShowContact] = useState(false);

  const handleCloseContact = () => setShowContact(false);
  const handleShowContact = (text) => {
      setShowContact(true)
  }
  
    return(
      <>
        <nav className="navbar navbar-expand-lg navbar-light mainNavbar fixed-top" id="navbar" >
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img className='img-fluid' src={logo} alt="logo" />
          </NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                  <NavLink className="nav-item nav-link" exact to="/">Home </NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink className="nav-link dropdown-toggle" to="/services" id="navbarDropdown" >
                  Services
                </NavLink>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" href="/services/p2p-exchange-development">P2P Exchange Development</a>
                  <a className="dropdown-item" href="/services/crypto-payment-development">Crypto Payment Development</a>
                  <a className="dropdown-item" href="/services/EVM-compaitable-blockchain-solutions">EVM Compaitable Blockchain Solutions</a>
                  <a className="dropdown-item" href="/services/decentralized-exchange-development">Decentralized Exchange Development</a>
                  <a className="dropdown-item" href="/services/blockchain-development">Blockchain Development</a>
                  <a className="dropdown-item" href="/services/smartcontract">Smart Contracts</a>
                  <a className="dropdown-item" href="/services/NFT-development">NFT Development</a>
                  <a className="dropdown-item" href="#">Blockchain Consulting</a>
                  <a className="dropdown-item" href="/services/webapp">Web Apps</a>
                  <a className="dropdown-item" href="#">Mobile Apps</a>
                  <a className="dropdown-item" href="#">UI/UX Design</a>
                  {/* <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#">Something else here</a> */}
                </div>
              </li>
              <li className="nav-item">
                  <HashLink className="nav-item nav-link"  to="/#client">Client</HashLink>
              </li>
              <li className="nav-item">
                  <HashLink className="nav-item nav-link"  to="/#ourProject">Portfolio</HashLink>
              </li>
              <li className="nav-item">
                  <HashLink className="nav-item nav-link" to="/#about">About Us</HashLink>
              </li>
              <li className="nav-item ">
                <a className="nav-item nav-link" href="https://dappomatics.com/blog/" >Blog</a>
              </li>
              <li className="nav-item">
                  <a className="nav-item nav-link" onClick={()=>handleShow("Let's Talk")}>Contact</a>
              </li>
            </ul>
            <div className='navContactBtn '>
              <span onClick={handleShowContact} style={{backgroundColor: "#2979FF"}}><i className="fas fa-phone-alt"></i></span>
              <button className="themeBtn calendlySchedule fs-0" 
              // onClick={()=>handleShow("Let's Talk")}
              >Schedule demo</button>
            </div>

          </div>
        </div>
      </nav>
      <Modal show={showContact} onHide={handleCloseContact} className='contactModal2' centered={true}>
        <Modal.Body>
          <div className="mainContent">
            <img src={closeIco} className="closeIco" alt="" onClick={handleCloseContact} />
            {/* <i class="fa fa-times closeIco" ></i> */}
            <div className="contactNumM">
              <div className="contactImg mb-3">
                <img src={germany} alt="" />
                <a href="tel:+4915736735515">+49 1573 6735515</a> 
              </div>
              <div className="contactImg mb-3">
                <img src={germany} alt="" />
                <a href="tel:+4915775568031">+49 1577 5568031</a> 
              </div>
              <div className="contactImg">
                <img src={whatsapp} alt="" />
                <a target='_blank' href="https://api.whatsapp.com/send?phone=923352491559">+92 335 2491559</a> 
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      </>
    )
}