import React from "react";
import { Modal } from "react-bootstrap";
import closeIco from "../assets/images/closeIco.svg";
import { motion } from "framer-motion";

const SubscribeModal = ({ showSubscribeModal, setShowSubscribeModal }) => {
  return (
    <>
      <Modal
        show={showSubscribeModal}
        onHide={() => setShowSubscribeModal(false)}
        centered={true}
      >
        <Modal.Body>
          <div className=" contactModal2 ">
            <img
              src={closeIco}
              className="closeIco"
              alt="cloceIco"
              onClick={() => setShowSubscribeModal(false)}
            />
          </div>

          <div className="subscribeModal_svg ">
            <svg viewBox="0 0 53.19 53.19">
              <g>
                <motion.circle
                  stroke="#51e051"
                  strokeWidth={3}
                  fill="#51e051"
                  cx={26.59}
                  cy={26.59}
                  r={24}
                  initial={{
                    scale: 0,
                    opacity: 0,
                  }}
                  animate={{
                    scale: 1,
                    opacity: 1,
                  }}
                />
                <motion.path
                  stroke="#FFFFFF"
                  strokeWidth={3}
                  strokeLinecap="round"
                  fill="transparent"
                  d="M12.29 26.59l10.98 10.42 17.49-18.23"
                  initial={{
                    opacity: 0,
                    pathLength: 0,
                  }}
                  animate={{
                    opacity: 1,
                    pathLength: 1,
                  }}
                  transition={{
                    delay: 0.2,
                    duration: 0.5,
                  }}
                />
              </g>
            </svg>
          </div>

          <h1 className=" subscribeModal_title ">
            Thanks for subscribing to our newsletter!
          </h1>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubscribeModal;
