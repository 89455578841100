import React, { useEffect } from "react";
import cryptoPD from "../../assets/images/cryptoPaymentDevelopment/cryptoPD.png";
import cryptoPD1 from "../../assets/images/cryptoPaymentDevelopment/cryptoPD1.png";
import cryptoPD2 from "../../assets/images/cryptoPaymentDevelopment/cryptoPD2.png";
import cryptoPD3 from "../../assets/images/cryptoPaymentDevelopment/cryptoPD3.png";
import cryptoPD4 from "../../assets/images/cryptoPaymentDevelopment/cryptoPD4.png";
import { animate } from "../../assets/js/animate";
import { Navbar } from "../navbar";
import "./cryptoPD.css";

import { GetConnectWithUs } from "../connectWithUs";
import { Footer } from "../footer";

const CryptoPaymentDevelopment = ({ handleShow }) => {
  useEffect(() => {
    animate();
  }, []);
  return (
    <>
      <div>
        <div className=" flex flex-col background-image bg-cover">
          {/* Navbar */}
          <Navbar handleShow={handleShow} />

          {/* Crypto Payment Development */}
          <div className="container mx-auto p-4 md:p-8 lg:p-12 xl:p-20">
            <div className="flex lg:flex-row flex-col flex-1 flex-wrap justify-between items-start mt-20">
              <div className="lg:w-1/2 flex flex-col items-start justify-center  mt-[60px]">
                <div className="text-[42px] sm:text-[75px] xl:text-8xl font-[1000]">
                  Crypto Payment <br />
                  Development
                </div>
                <div className="pt-[24px] text-[16px] font-normal ">
                  Welcome to Dappomatics: Your Trusted Partner for Custom Crypto
                  Wallet Development
                </div>
              </div>
              <div className="w-2/2 flex flex-1 items-center justify-center lg:ml-8 max-lg:mt-12">
                <img src={cryptoPD} alt="Dappomatics Dapp" />
              </div>
            </div>
          </div>

          {/* Crypto Payment Development Services */}
          <div className="bg-[#2979FF] w-full mt-24 ">
            <div className="flex flex-row flex-1 items-center h-full justify-center py-[45px] ">
              <div className="text-[16px] font-normal text-center items-center justify-center break-words max-w-7xl max-xl:px-4 text-white">
                At Dappomatics, we specialize in building custom P2P crypto
                exchanges tailored to meet your specific business needs. With
                years of experience, technical expertise, and a deep
                understanding of the cryptocurrency market and its challenges,
                we are your go-to solution for building a robust, secure, and
                user-friendly exchange that reflects your brand’s values.
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex lg:flex-row flex-1 flex-col bg-[#F8F8F8] rounded-[50px] items-center justify-between py-4 mt-[120px] max-w-[90%] 2xl:max-w-[80%] ">
            <div className="lg:w-1/2 justify-start py-8 ">
              <div className="flex flex-col text-left items-start justify-start max-md:pl-[20px] pl-[40px] pr-2 max-w-2xl ">
                <div className="lg:border-r-4 max-lg:border-b-4 pr-2 max-lg:pb-10 border-[#E6E6E6]">
                  <div className="text-black text-[32px] font-black leading-10">
                    Secure Payment Solutions <br />
                    Built to Meet Your Business <br />
                    Needs
                  </div>
                  <div className="text-black text-[15px] font-normal leading-7 pt-[35px] max-lg:max-w-3xl pr-4 lg:pr-20 pb-[20px] break-words max-w-full h-full">
                    We understand the challenges that businesses face when it
                    comes to integrating crypto payment solutions into their
                    operations. That’s why we offer a range of services that
                    provide businesses with the tools they need to manage,
                    process, and secure transactions with ease. Whether you’re
                    looking to build a new custom payment exchange, or looking
                    to enhance the security and efficiency of existing payment
                    systems, we’ve got you covered.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-center xl:pr-10 2xl:pr-40 ">
                <img src={cryptoPD1} alt="Dappomatics Dapp" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="flex lg:flex-row flex-1 flex-col bg-[#F8F8F8] rounded-[50px] items-center justify-between py-4 mt-[120px] max-w-[90%] 2xl:max-w-[80%] ">
            <div className="lg:w-1/2 flex items-center justify-center xl:pl-0 py-8 ">
              <div className="lg:border-r-4 max-lg:border-b-4 xl:pr-10 max-lg:pb-10 border-[#E6E6E6]">
                <img src={cryptoPD2} alt="Dappomatics Dapp" />
              </div>
            </div>
            <div className="lg:w-1/2 justify-start py-8 ">
              <div className="flex flex-col text-left items-start justify-start max-md:pl-[20px] pr-4 max-w-2xl ">
                <div className="text-black text-[32px] pl-6 font-black leading-10">
                  Expertise You Can Rely On <br />
                </div>
                <div className="text-black text-[15px] font-normal leading-7 pt-[35px] max-lg:max-w-3xl pl-6 pb-[20px] break-words max-w-2xl h-full">
                  At Dappomatics, our team of experts has deep knowledge and
                  understanding of the crypto market and its challenges. We take
                  pride in providing businesses with cutting-edge solutions that
                  are not only tailored to meet their specific needs, but also
                  future-proof their operations. Our expertise ranges from
                  blockchain technologies to smart contract development,
                  ensuring that we can deliver the best results for your
                  business.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex lg:flex-row flex-1 flex-col bg-[#F8F8F8] rounded-[50px] items-center justify-between py-4 mt-[120px] max-w-[90%]   2xl:max-w-[80%] ">
            <div className="lg:w-1/2 justify-start py-8 ">
              <div className="flex flex-col text-left items-start justify-start max-md:pl-[20px] pl-[40px] pr-2 max-w-2xl ">
              <div className="lg:border-r-4 max-lg:border-b-4 pr-2 max-lg:pb-10 border-[#E6E6E6]">

                  <div className="text-black text-[32px] font-black leading-10">
                    Enhance Your Brand’s <br />
                    Online Presence
                  </div>
                  <div className="text-black text-[15px] font-normal leading-7 pt-[35px] max-lg:max-w-3xl pr-4 lg:pr-20 pb-[20px] break-words max-w-full h-full">
                    In today’s digital age, having a robust online presence is
                    essential for businesses looking to grow and expand their
                    operations. At Dappomatics, we understand this better than
                    anyone. That’s why we design and develop custom exchange
                    platforms that are not only secure and efficient but also
                    user-friendly and visually appealing. We believe in
                    delivering payment solutions that perfectly align with your
                    brand’s vision, enhancing your online presence and customer
                    outreach.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-center xl:pr-10 2xl:pr-40  ">
                <img src={cryptoPD3} alt="Dappomatics Dapp" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="flex lg:flex-row flex-1 flex-col bg-[#F8F8F8] rounded-[50px] items-center justify-between py-4 mt-[120px] max-w-[90%] 2xl:max-w-[80%] ">
            <div className="lg:w-1/2 flex items-center justify-center xl:pl-0 py-8 ">
              <div className="lg:border-r-4 max-lg:border-b-4 pr-10 pl-14 lg:pr-20 max-lg:pb-10 border-[#E6E6E6]">

                <img src={cryptoPD4} alt="Dappomatics Dapp" />
              </div>
            </div>
            <div className="lg:w-1/2 justify-start py-8 ">
              <div className="flex flex-col text-left items-start justify-start max-md:pl-[20px] pr-4 max-w-2xl ">
                <div className="text-black text-[32px] pl-6 font-black leading-10">
                  Collaborate with Dappomatics <br />
                </div>
                <div className="text-black text-[15px] font-normal leading-7 pt-[35px] max-lg:max-w-3xl pl-6 pb-[20px] break-words max-w-2xl h-full">

                  Partnering with Dappomatics will give your business access to
                  deep technical expertise, a thorough understanding of the
                  crypto market, and custom payment platforms that perfectly
                  match your business needs. We prioritize our clients' success
                  and will work hand in hand with your team to ensure that your
                  payment systems are optimized to reduce costs, minimize
                  operational challenges, and enhance the user experience.
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Get Started */}
        <div className="flex sm:flex-row max-sm:flex-col bg-gradient-to-r from-[#5C0CEC] via-[#2979FF] to-[#1FCBFF] w-full py-2 sm:h-[200px] mt-32 ">
          <div className="flex sm:flex-row max-sm:flex-col flex-1 items-center justify-between py-[60px] xl:pl-52 xl:pr-36">
            <div className="sm:border-r-2 max-sm:border-b-2 border-white ">
              <div className="text-[32px] font-semibold text-white text-left whitespace-nowrap pr-24 max-xl:px-4 pl-[100px] py-8 ">
                Get in touch <br />
              </div>
            </div>
            <div className="text-[16px] font-normal text-left max-sm:text-center items-center justify-center break-words max-sm:pt-4 text-white pl-16 max-xl:px-4 pr-24 ">
              If you’re interested in partnering with Dappomatics to build
              custom crypto payment development solutions that meet your
              business needs and exceed your expectations, please don’t hesitate
              to get in touch. Our team is ready and waiting to take your
              business to the next level.
            </div>
          </div>
        </div>
      </div>

      {/* Get Connect With Us */}
      <div className="mt-24">
        <GetConnectWithUs />
      </div>
      {/* Footer */}
      <div className="mt-24">
        <Footer />
      </div>
    </>
  );
};

export default CryptoPaymentDevelopment;
