import React from 'react'
import { useEffect } from 'react'
import { Navbar } from '../navbar'
import "./service.css"
import serviceBanner from "../../assets/images/serviceBanner.png"
import webApp from "../../assets/images/webApp.png"
import mobileApp from "../../assets/images/mobileApp.png"
import blockchainDev from "../../assets/images/blockchainDev.png"
import decentralize from "../../assets/images/decentralize.png"
import enterpriseSol from "../../assets/images/enterpriseSol.png"
import mobileUi from "../../assets/images/mobileUi.png"
import { Footer } from '../footer'
import { animate } from '../../assets/js/animate'
import { GetConnectWithUs } from '../connectWithUs'
import { Link } from 'react-router-dom'
export const Services = ({handleShow}) => {
  useEffect(() => {
    animate()
}, [])
  return (
    <>
        <Navbar handleShow={handleShow} />
        <div className="servicePage">
        <section className='secOne' id='sec1'>
                <div className="container">
                    <div className="row ai-center">
                        <div className="col-lg-6">
                            <h1>We Are Dappomatics</h1>
                            <p>Building Decentralized Apps</p>
                            <div className="btnMain">
                                <button className="themeBtn" onClick={()=>handleShow("Let's Connect")}>Let's Talk </button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="imgDiv">
                                <img className='img-fluid' src={serviceBanner} alt="DEFI" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* projects counter */}
            <section className='serviceSlider' >
              <marquee behavior="smooth" direction="right">
                <div className="contentCard">
                    <p>Web Apps</p>
                    <p>-</p>
                    <p>Mobile Apps</p>
                    <p>-</p>
                    <p>Blockchain</p>
                    <p>-</p>
                    <p>Enterprise Solution</p>
                    <p>-</p>
                    <p>UI/UX Design </p>
                </div>
              </marquee>
                
            </section>
            <section className='serviceSec'>
              <div className="container">
                <div className="head animatable fadeInUpLong ">Services We Are Offering</div>
                <div className="desc animatable fadeInUpLong animationDelay100 ">Welcome to Dappomatics' services page. We are a leading software development company that offers a range of high-quality services for individuals and businesses alike. Our team of skilled professionals has years of experience in delivering effective solutions for web application development, mobile application development, blockchain development, decentralized application development, enterprise solutions, and mobile UI/UX design</div>
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1">
                    <div className="serviceCard">
                      <h2 className='animatable fadeInUpLong '>Web Application Development:</h2>
                      <p className='animatable fadeInUpLong animationDelay200 '>Dappomatics' web application development service is designed to cater to businesses of all sizes. We provide fully customized, secure, and scalable web applications that cater to specific business needs. Our team of expert developers use modern technologies and frameworks to ensure flawless performance, user-friendly interfaces, and outstanding functionality.</p>
                      <Link to="/services/webapp" className="themeBtn animatable fadeInUpLong animationDelay200 ">Learn More</Link>
                    </div>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2">
                    <div className="imgDiv">
                      <img className='img-fluid' src={webApp} alt="" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="imgDiv">
                      <img className='img-fluid' src={mobileApp} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="serviceCard">
                      <h2 className='animatable fadeInUpLong '>Mobile Application Development:</h2>
                      <p className='animatable fadeInUpLong animationDelay200 '>In today's fast-paced world, having a mobile application for your business is essential. At Dappomatics, we develop mobile applications that are tailored to the unique needs of our clients. Our mobile apps are optimized for different mobile devices, making them responsive, user-friendly, and visually appealing. We ensure that the final product is reliable, secure, and stable.</p>
                      <button className="themeBtn animatable fadeInUpLong animationDelay200 ">Learn More</button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1">
                    <div className="serviceCard">
                      <h2 className='animatable fadeInUpLong '>Blockchain Development:</h2>
                      <p className='animatable fadeInUpLong animationDelay200 '>Dappomatics' blockchain development team specializes in creating secure, reliable, and scalable blockchain solutions for businesses. We  offer comprehensive blockchain development services, from conception to implementation. Our blockchain solutions ensure a high degree of transparency, security, and reliability that can revolutionize the way you do business.</p>
                      <Link to="/services/blockchain-development" className="themeBtn animatable fadeInUpLong animationDelay200 ">Learn More</Link>
                    </div>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2">
                    <div className="imgDiv">
                      <img className='img-fluid' src={blockchainDev} alt="" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="imgDiv">
                      <img className='img-fluid' src={decentralize} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="serviceCard">
                      <h2 className='animatable fadeInUpLong '>Decentralized Application Development:</h2>
                      <p className='animatable fadeInUpLong animationDelay200 '>Our decentralized application development service aims to offer businesses with secure and fast decentralized solutions customized to the client's needs. We leverage the modern frameworks to build robust, secure, and reliable decentralized applications. Our approach emphasizes transparency, security, and reliability in the development of any decentralized application.</p>
                      <button className="themeBtn animatable fadeInUpLong animationDelay200 ">Learn More</button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1">
                    <div className="serviceCard">
                      <h2 className='animatable fadeInUpLong'>Enterprise Solutions:</h2>
                      <p className='animatable fadeInUpLong animationDelay200 '>Our enterprise solutions cater to the unique needs of businesses, whether small or large. We develop enterprise-grade solutions that meet the current and future demands of businesses. Our expert developers can customize solutions that can help you streamline processes, increase productivity, and optimize operational efficiency.</p>
                      <button className="themeBtn animatable fadeInUpLong animationDelay200">Learn More</button>
                    </div>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2">
                    <div className="imgDiv">
                      <img className='img-fluid' src={enterpriseSol} alt="" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="imgDiv">
                      <img className='img-fluid' src={mobileUi} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="serviceCard">
                      <h2 className='animatable fadeInUpLong '>Mobile UI/UX Design:</h2>
                      <p className='animatable fadeInUpLong animationDelay200'>At Dappomatics, we understand the importance of a well-designed user interface. Our team of experts specializes in designing mobile interfaces that are intuitive, user-friendly, and visually appealing. Our cutting-edge design techniques ensure that your mobile application stands out from the crowd, resulting in higher user engagement and customer loyalty.</p>
                      <button className="themeBtn animatable fadeInUpLong animationDelay200">Learn More</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className='concSec'>
              <div className="container">
                <p>In conclusion, Dappomatics' services provide clients with the best and most comprehensive software development solutions. Our services are designed to cater to specific business needs, and we deliver nothing but the best. Get in touch with us today and let us help you take your business to the next level.</p>
              </div>
            </section>
             {/* connect section */}
            <GetConnectWithUs />
            <Footer />
        </div>
    </>
  )
}
