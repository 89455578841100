import React, { useEffect } from "react";

import { animate } from "../../assets/js/animate";
import "./cryptoPD.css";
import { Navbar } from "../navbar";
import { GetConnectWithUs } from "../connectWithUs";
import { Footer } from "../footer";

import evm from "../../assets/images/EVMcompaitableBlockchainSolutions/evm.png";
import evm1 from "../../assets/images/EVMcompaitableBlockchainSolutions/evm1.png";
import evm2 from "../../assets/images/EVMcompaitableBlockchainSolutions/evm2.png";
import evm3 from "../../assets/images/EVMcompaitableBlockchainSolutions/evm3.png";
import evm4 from "../../assets/images/EVMcompaitableBlockchainSolutions/evm4.png";
import evm5 from "../../assets/images/EVMcompaitableBlockchainSolutions/evm5.png";

const EVMcompaitableBlockchainSolutions = ({ handleShow }) => {
  useEffect(() => {
    animate();
  }, []);
  return (
    <>
      <div>
        <div className=" flex flex-col">
          {/* Navbar */}
          <Navbar handleShow={handleShow} />

          {/* EVM Compaitable Blockchain Solutions */}
          <div className="container mx-auto p-4 md:p-8 lg:p-12 xl:p-20">
            <div className="flex lg:flex-row flex-col flex-1 flex-wrap justify-between items-start mt-20">
              <div className="lg:w-1/2 flex flex-col items-start justify-center mt-[60px]">
                <div className="text-[42px] sm:text-[75px] xl:text-8xl font-[1000]">
                  EVM Compatible <br />
                  Blockchain <br />
                  Solutions
                </div>
                <div className="pt-[24px] text-[16px] font-normal ">
                  Dappomatics: Your Trusted EVM Compatible Blockchain Service
                  Provider
                </div>
              </div>
              <div className="w-2/2 flex flex-1 items-center justify-center lg:ml-8 mt-6 max-lg:mt-12">
                <img src={evm} alt="Dappomatics Dapp" />
              </div>
            </div>
          </div>
          <div className="evm-rectangle-gradient w-full mt-24 ">
            <div className="flex flex-row items-center justify-center mt-10 2xl:max-w-7xl max-w-[95%] mx-auto">
              <div className="text-[16px] font-normal text-center items-center justify-center break-words text-white">
                <p className="leading-[30px] ">
                  Dappomatics is at the forefront of delivering superior
                  blockchain solutions that are tailored to meet the unique
                  needs of our clients. With years of experience in the
                  industry, our team of experts is highly specialized in
                  providing secure, reliable, and user-friendly Ethereum
                  blockchain services.
                </p>
                <p className="leading-[30px] mt-[15px] mb-10 ">
                  Our landing page is designed to be informative, engaging, and
                  easy to navigate. Here are some of the key headings that you
                  will find on our landing page.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* EVM Comapitable Blcokchain Solutions Services */}

        <div className="flex flex-wrap items-center justify-center mt-[120px] mx-auto max-w-[90%]">
          <div className="text-[40px] font-bold text-center leading-[64px] ">
            Introduction
          </div>
          <div className="text-[16px] font-normal text-center mt-[22px] xl:px-32 leading-8 ">
            Our introduction section provides a brief overview of our company,
            our mission, and our commitment to delivering exceptional blockchain
            solutions. We highlight our strengths andexpertise in the field,
            showcasing our achievements and successes.
          </div>

          <div className="flex flex-row items-center justify-between mt-[80px]">
            <div className="flex flex-col max-sm:flex-1 items-center justify-center sm:w-[580px] sm:h-[494px] bg-[#F8F8F8] rounded-[36px] ">
              <div className="mt-[52px]  ">
                <img src={evm1} alt="EVM Comapitable Blcokchain Solutions " />
              </div>
              <div className="text-[24px] font-bold text-center mt-8 leading-[30px] ">
                Services Offered
              </div>
              <div className="text-[14px] font-normal text-center mt-[22px] mb-[60px] mx-10 leading-[30px] ">
                Under this section, we provide a comprehensive list of the
                blockchain services that we offer. Our services have been
                tailored to meet the unique needs of our diverse clientele. We
                offer Ethereum blockchain development, smart contract
                development, DApp development, and more.
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between mt-[80px] xl:pl-[40px]  ">
            <div className="flex flex-col  items-center justify-center max-sm:flex-1 sm:w-[580px] sm:h-[494px] bg-[#F8F8F8] rounded-[36px] ">
              <div className="mt-[52px]  ">
                <img src={evm2} alt="EVM Comapitable Blcokchain Solutions " />
              </div>
              <div className="text-[24px] font-bold text-center mt-8 leading-[30px] ">
                Our Approach
              </div>
              <div className="text-[14px] font-normal text-center mt-[22px] mb-[60px] mx-9 leading-[30px] ">
                At Dappomatics, we have developed a unique approach to
                delivering blockchain solutions. We pride ourselves on our agile
                and collaborative approach, which allows us to work closely with
                our clients to ensure that their needs are met. Our team is
                always available to offer advice, feedback, and insights.
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between mt-[80px] ">
            <div className="flex flex-col items-center justify-center max-sm:flex-1 sm:w-[580px] sm:h-[494px] bg-[#F8F8F8] rounded-[36px] ">
              <div className="mt-[52px]  ">
                <img src={evm3} alt="EVM Comapitable Blcokchain Solutions " />
              </div>
              <div className="text-[24px] font-bold text-center mt-8 leading-[30px] ">
                Our Team
              </div>
              <div className="text-[14px] font-normal text-center mt-[22px] mb-[60px] mx-10 leading-[30px] ">
                Our team of experts is comprised of highly skilled and
                experienced blockchain professionals. We work together to
                deliver superior solutions that meet the needs of our clients.
                We highlight the unique skills and expertise of our team
                members, showcasing the collective talent and knowledge of our
                team.
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between mt-[80px] xl:pl-[40px] ">
            <div className="flex flex-col items-center justify-center max-sm:flex-1 sm:w-[580px] sm:h-[494px] bg-[#F8F8F8] rounded-[36px] ">
              <div className="mt-[52px]  ">
                <img src={evm4} alt="EVM Comapitable Blcokchain Solutions " />
              </div>
              <div className="text-[24px] font-bold text-center mt-8 leading-[30px] ">
                Our Portfolio
              </div>
              <div className="text-[14px] font-normal text-center mt-[22px] mb-[60px] mx-10 leading-[30px] ">
                We have a strong portfolio that demonstrates our ability to
                deliver high-quality blockchain solutions to our clients. We
                showcase our previous work, which includes successful blockchain
                projects in diverse industries.
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between mt-[80px]  ">
            <div className="flex flex-col items-center justify-center max-sm:flex-1 sm:w-[580px] sm:h-[494px] bg-[#F8F8F8] rounded-[36px] ">
              <div className="mt-[52px]  ">
                <img
                  className="w-[155px] h-[155px] "
                  src={evm5}
                  alt="EVM Comapitable Blcokchain Solutions "
                />
              </div>
              <div className="text-[24px] font-bold text-center mt-8 leading-[30px] ">
                Contact Us
              </div>
              <div className="text-[14px] font-normal text-center mt-[22px] mb-[60px] mx-10 leading-[30px] ">
                To get started on your blockchain project or to learn more about
                our services, please reach out to us via our contact page. Our
                team of experts is always available to answer your questions and
                to offer guidance. We look forward to connecting with you!
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Get Connect With Us */}
      <div className="mt-24">
        <GetConnectWithUs />
      </div>
      {/* Footer */}
      <div className="mt-24">
        <Footer />
      </div>
    </>
  );
};

export default EVMcompaitableBlockchainSolutions;
