import React from 'react'
import { useEffect } from 'react'
import { Navbar } from '../navbar'
import "./service.css"
import ExchangeBanner from "../../assets/images/ExchangeDevelopment/banner.png"
import Group1 from "../../assets/images/ExchangeDevelopment/group-1.png"
import Group2 from "../../assets/images/ExchangeDevelopment/group-2.png"
import Group3 from "../../assets/images/ExchangeDevelopment/group-3.png"

import { Footer } from '../footer'
import { animate } from '../../assets/js/animate'
import { GetConnectWithUs } from '../connectWithUs'
import { ProjectCounter } from './projectCounterSec'

export const DecentralizedExchangeDevelopment = ({ handleShow }) => {
    useEffect(() => {
        animate()
    }, [])

    return (
        <>
            <Navbar handleShow={handleShow} />
            <div className="servicePage">
                <section className='secOne' id='sec1'>
                    <div className="container">
                        <div className="row ai-center">
                            <div className="col-lg-6">
                                <h1>Decentralized <br /> Exchange <br /> Development</h1>
                                <p>Take Your Trading to the Next Level with Dappomatics.</p>
                            </div>
                            <div className="col-lg-6">
                                <div className="imgDiv">
                                    <img className='img-fluid' src={ExchangeBanner} alt="DEFI" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




                <section className='projectCounter1' >
                    <div className='container'>
                        <div className="counterMain">
                            <div className="countCard w-100">
                                <h3>Leading Decentralized Exchange Development Services</h3>
                            </div>
                        </div>
                    </div>
                </section>



                <section className='serviceSec'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="serviceCard">
                                    <h2 className='animatable fadeInUpLong '>What is a Decentralized Exchange?</h2>
                                    <p className='animatable fadeInUpLong animationDelay200 '>A decentralized exchange is an online platform or marketplace where users can trade digital assets without the need for a centralized third party. Decentralized exchanges are designed to provide a secure, reliable, and user-friendly trading experience, allowing users to trade assets without the need for a traditional central authority.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="imgDiv">
                                    <img className='img-fluid' src={Group1} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="imgDiv">
                                    <img className='img-fluid' src={Group2} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="serviceCard">
                                    <h2 className='animatable fadeInUpLong '>Why Choose Dappomatics?</h2>
                                    <p className='animatable fadeInUpLong animationDelay200 '>At Dappomatics, we are leading the way in decentralized exchange development services. We provide secure, reliable, and user-friendly decentralized exchange solutions that are tailored to the needs of our clients. With years of experience in the industry, our team of experts are well-equipped to handle any project or task that comes our way.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="serviceCard">
                                    <h2 className='animatable fadeInUpLong '> Our Mission</h2>
                                    <p className='animatable fadeInUpLong animationDelay200 '>At Dappomatics, our mission is to provide our clients with the best possible decentralized exchange solutions. We strive to ensure that our solutions are secure, reliable, and user-friendly, allowing our users to easily and securely trade digital assets without worry. With our advanced development services, we make it easy for our clients to take their trading to the next level.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="imgDiv">
                                    <img className='img-fluid' src={Group3} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* connect section */}
                <GetConnectWithUs />
                <Footer />
            </div>
        </>
    )
}