import React from 'react'
import { useEffect } from 'react'
import { Navbar } from '../navbar'
import "./service.css"
import { Footer } from '../footer'
import { animate } from '../../assets/js/animate'
import { GetConnectWithUs } from '../connectWithUs'
import blockchainBanner from "../../assets/images/blockchainBanner.png"
import webApp from "../../assets/images/webApp.png"
import mobileApp from "../../assets/images/mobileApp.png"
import blockchainDev from "../../assets/images/blockchainDev.png"
import extensiveBlockchain from "../../assets/images/extensiveBlockchain.png"
import SCBlockchain from "../../assets/images/SCBlockchain.png"
import tokonizationBlockchain from "../../assets/images/tokonizationBlockchain.png"
import blockchainConsult from "../../assets/images/blockchainConsult.png"
import blockchainTest from "../../assets/images/blockchainTest.png"

export const BlockChainConsulting = ({handleShow}) => {
  useEffect(() => {
    animate()
  // window.scrollTo(0, 0);

}, [])

  return (
    <>
        <Navbar handleShow={handleShow} />
        <div className="servicePage">
            <section className='secOne' id='sec1'>
                <div className="container">
                    <div className="row ai-center">
                        <div className="col-lg-6">
                            <h1 className='animatable fadeInUpLong'>Blockchain Development Services Offered by Dappomatics</h1>
                            {/* <p className='animatable fadeInUpLong animationDelay200 '></p> */}
                          
                        </div>
                        <div className="col-lg-6">
                            <div className="imgDiv">
                                <img className='img-fluid' src={blockchainBanner} alt="DEFI" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='sevicesWeOffer2 text-center'>
              <div className="container">
                <p>Dappomatics is a leading software development company that specializes in offering top-notch blockchain development services. Our team of experienced blockchain developers is committed to creating secure, reliable, and scalable blockchain solutions that can transform the way businesses operate.</p>
              </div>
            </section>

            <section className='serviceSec'>
              <div className="container">
                <div className="row jc-center mb-0">
                  <div className="col-lg-6 mb-4">
                    <ServiceCard image={extensiveBlockchain} heading={"Extensive Blockchain Expertise"} text={"Dappomatics is a leading blockchain development company with years of experience in developing smart contracts on various platforms. Our team's expertise in blockchain technology allows us to provide best smart contract solutions."} />
                  </div>
                  <div className="col-lg-6 mb-4">
                    <ServiceCard image={SCBlockchain} heading={"Smart Contract Development"} text={"Our smart contract developers utilize cutting-edge technology and best practices to create robust, secure, and efficient smart contracts for our clients. This ensures that every customer gets a customized solution that works seamlessly and efficiently."} />
                  </div>
                  <div className="col-lg-6 mb-4">
                    <ServiceCard image={tokonizationBlockchain} heading={"Tokenization Service"} text={"Dappomatics is renowned for its innovative approach to smart contract development. We continuously update our service offerings with the latest technologies and trends so that we can provide our clients with solutions that are ahead of their time."} />
                  </div>
                  <div className="col-lg-6 mb-4">
                    <ServiceCard image={blockchainConsult} heading={"Blockchain Consulting"} text={"Our smart contract development process has been designed with security and transparency in mind. Our developers perform rigorous testing and validation of the 12qsmart contracts to ensure that they are secure and free of errors."} />
                  </div>
              
                </div>
               
              </div>
            </section>
            <div className="fullGraySec">
                <div className="container">
                    <div className="serviceCard text-center mw-100">
                        <div className="row ai-center">
                            <div className="col-md-6">
                                    <div className="imgDiv lg">
                                        <img className='img-fluid' src={blockchainTest} alt="" />
                                    </div>
                                </div>
                            <div className="col-md-6">
                                <h4 className='animatable fadeInUpLong '>Blockchain Testing & Auditing</h4>
                                <p className='animatable fadeInUpLong animationDelay200 '>Our blockchain development team provides comprehensive testing and auditing services that ensure high degrees of transparency, security, and reliability. We leverage the best-in-class tools and technologies to deliver flawless blockchain solutions that are secure and scalable</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className='sevicesWeOffer2 text-center'>
              <div className="container">
                <h2 className='mb-4'>Conclusion</h2>
                <p>Dappomatics is a leading software development company that specializes in offering top-notch blockchain development services. Our team of experienced blockchain developers is committed to creating secure, reliable, and scalable blockchain solutions that can transform the way businesses operate.</p>
              </div>
            </section>
             {/* connect section */}
            <GetConnectWithUs />
            <Footer />
        </div>
    </>
  )
}

const ServiceCard = ({image,heading,text}) => {
    return(
        <div className="serviceCard text-center mw-100">
            <div className="imgDiv lg">
                <img className='img-fluid' src={image} alt="" />
            </div>
          <h4 className='animatable fadeInUpLong '>{heading}</h4>
          <p className='animatable fadeInUpLong animationDelay200 '>{text}</p>
        </div>
    )
} 