import React, { useState } from "react";
import SubscribeModal from "./SubscribeModal";

export const GetConnectWithUs = () => {
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValidEmail = (email) => {
      const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return pattern.test(email);
    };
    if (!isValidEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    setLoading(true);

    try {
      // Email Sending Logic
      // console.log(email);

      setLoading(false);
      setShowSubscribeModal(true);
      setEmail("");
    } catch (error) {
      setLoading(false);
      console.error(error);
      alert("Something went wrong.");
    }
  };

  return (
    <section className="connectSec mb-5 ">
      <div className="container">
        <h1 className="secHeading mb-2">Get Connected with Us</h1>
        <div className="desc">Important update only, we promise</div>
        <form onSubmit={(e) => handleSubmit(e)} className="inputMain">
          <input
            name="email"
            type="email"
            autoComplete="off"
            required
            value={email}
            onChange={handleChange}
            placeholder="Your email"
          />
          <button className="themeBtn">
            {loading ? "submitting" : "Submit"}
          </button>
        </form>
      </div>

      <SubscribeModal
        showSubscribeModal={showSubscribeModal}
        setShowSubscribeModal={setShowSubscribeModal}
      />
    </section>
  );
};
