import React, { useEffect, useState } from 'react'
import { Navbar } from './navbar'
import { animate } from '../assets/js/animate'
import $ from "jquery";
import Slider from "react-slick";


import Banner from '../assets/images/Homepage/banner.png'
import _servece1 from '../assets/images/Homepage/servece1.png'
import _servece2 from '../assets/images/Homepage/servece2.png'
import _servece3 from '../assets/images/Homepage/servece3.png'
import _servece4 from '../assets/images/Homepage/servece4.png'
import specialService from '../assets/images/Homepage/specialService.png'



import sec1Banner from '../assets/images/sec1Banner.png'
import serviceBanner from '../assets/images/serviceBanner.png'
import blockchainBanner from '../assets/images/blockchainBanner.png'
import blockchain from '../assets/images/blockchain.svg'
import specialIco from '../assets/images/specialIco.svg'
import rubik from '../assets/images/rubik.svg'

import ammar from '../assets/images/Homepage/amm.png'
import ahsan from '../assets/images/Homepage/ahs.png'
import affan from '../assets/images/Homepage/aff.png'
import ibtisam from '../assets/images/Homepage/ibt.png'
import fazal from '../assets/images/Homepage/faz.png'
import mustafa from '../assets/images/Homepage/mus.png'

import arrowLeft from '../assets/images/arrowLeft.svg'
import arrowRight from '../assets/images/arrowRight.svg'
import settingIco1 from '../assets/images/settingIco1.svg'
import settingIco2 from '../assets/images/settingIco2.svg'
import settingIco3 from '../assets/images/settingIco3.svg'

// project
import bitcoinpk from '../assets/images/project/bitcoinpk.webp'
import blockpix from '../assets/images/project/blockpix.svg'
import certifypk from '../assets/images/project/certifypk.webp'
import daologo from '../assets/images/project/dao-logo.webp'
import empireToken from '../assets/images/project/empireToken.svg'
import FortKnoxster from '../assets/images/project/FortKnoxster.svg'
import motor from '../assets/images/project/motor.webp'
import passiveIncome from '../assets/images/project/passiveIncome.webp'
import planet from '../assets/images/project/planet.webp'
import roburna from '../assets/images/project/roburna.webp'
import shiba from '../assets/images/project/shiba.webp'
import studio from '../assets/images/project/studio.webp'
import trustVibe from '../assets/images/project/trustVibe.svg'
// project


import { TestimonialSec } from './testimonialSec';
import Contact from './contact';
import { Footer } from './footer';
import { GetConnectWithUs } from './connectWithUs';
import { Link } from 'react-router-dom';
import { ProjectCounter } from './servicePage.js/projectCounterSec';

export const Home = ({ handleShow }) => {

    var [activeTeam, setActiveTeam] = useState({})
    var [activeNo, setActiveNo] = useState(0)

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },

        ]
    };

    const settings1 = {
        dots: true,
        infinite: true,
        speed: 600,
        autoplay: true,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const handleArrowLeft = () => {
        if (0 < activeNo) {
            setActiveNo(activeNo - 1)
        }
    }

    const handleArrowRight = () => {
        if (teamData.length - 1 > activeNo) {
            setActiveNo(activeNo + 1)
        }
    }

    useEffect(() => {
        animate()
    }, [])

    useEffect(() => {
        setActiveTeam(teamData[activeNo])
        console.log(activeNo);
    }, [activeNo])



    return (
        <>
            <Navbar handleShow={handleShow} />
            <section className='secOne' id='sec1'>
                <div className="container">
                    <div className="row ai-center">
                        <div className="col-lg-6">
                            <h1>We Are Dappomatics</h1>
                            <p>Building Decentralized Apps.</p>
                            <div className="btnMain">
                                <Link onClick={() => {handleShow("Let's Connect")} } className="themeBtn dib">Let's Talk</Link>
                            </div>
                            <p style={{ fontWeight: 600, marginTop: "25px" }}>Trusted by 100+ clients.</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="imgDiv">
                                <img className='w-100' src={Banner} alt="" />
                                {/* <img className='settingIco1' src={settingIco1} alt="" />
                                <img className='settingIco3' src={settingIco3} alt="" />
                                    <Slider {...settings1}>
                                    <img className="w-100" src={sec1Banner} />
                                    <img className="w-100 secondImg " src={serviceBanner} />
                                    <img className="w-100 secondImg " src={blockchainBanner} />
                                    
                                    </Slider> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* projects counter */}
            <ProjectCounter />


            {/* service */}
            <section className='specialService'>
                <div className="container">
                    <div className='secHeadingSmall text-center'>{"Services".toUpperCase()}</div>
                    <div className='secHeading text-center'>What We Do</div>
                    <div className="row">
                        <div className="col-md-3 animatable fadeInUpLong ">
                            <SpecialCard img={_servece1} title={"Blockchain Development"} desc={"Study and transformation of techniques, tools."} />
                        </div>
                        <div className="col-md-3 animatable fadeInUpLong ">
                            <SpecialCard img={_servece2} title={"Smart Contracts"} desc={"Study and transformation of techniques, tools."} />
                        </div>
                        <div className="col-md-3 animatable fadeInUpLong ">
                            <SpecialCard img={_servece3} title={"Web Apps"} desc={"Study and transformation of techniques, tools."} />
                        </div>
                        <div className="col-md-3 animatable fadeInUpLong">
                            <SpecialCard img={_servece4} title={"Blockchain Consulting"} desc={"Study and transformation of techniques, tools."} />
                        </div>
                    </div>
                    <div className="btnRow">
                        <div className="d-flex" style={{ justifyContent: "center" }}>
                            <Link to={"/services"} className="themeBtnOutline text-center me-sm-4" style={{ padding: "10px 48px", fontSize: "16px" }} >Explore More </Link>
                            <button className="themeBtn calendlySchedule" >Get In Touch</button>
                        </div>
                    </div>
                </div>
            </section>


            {/* special service */}
            <section className='specialService' style={{ background: "#2979FF" }}>
                <div className="container">
                    <div className='secHeading text-center text-white'>Our Special Services</div>
                    <div className="row">
                        <div className="col-md-4 column animatable fadeInUpLong ">
                            <SpecialServiceCard img={specialService} title={"NFT Development Solution"} desc={"Study and transformation of techniques, tools."} />
                        </div>
                        <div className="col-md-4 column animatable fadeInUpLong ">
                            <SpecialServiceCard img={specialService} title={"Solidity Development"} desc={"Study and transformation of techniques, tools."} />
                        </div>
                        <div className="col-md-4 column animatable fadeInUpLong ">
                            <SpecialServiceCard img={specialService} title={"Defi Development"} desc={"Study and transformation of techniques, tools."} />
                        </div>
                        <div className="col-md-4 column animatable fadeInUpLong">
                            <SpecialServiceCard img={specialService} title={"Launchpad Platform Development"} desc={"Study and transformation of techniques, tools."} />
                        </div>
                        <div className="col-md-4 column animatable fadeInUpLong">
                            <SpecialServiceCard img={specialService} title={"Digital Tokenization"} desc={"Study and transformation of techniques, tools."} />
                        </div>
                        <div className="col-md-4 column animatable fadeInUpLong">
                            <SpecialServiceCard img={specialService} title={"Dao Governance"} desc={"Study and transformation of techniques, tools."} />
                        </div>
                    </div>
                </div>
            </section>



            {/* what we do */}
            {/* <section className='whatWeDo'>
                <div className="container">
                    <div className='secTitle text-center'>Services</div>
                    <div className='secHeading text-center'>What We Do</div>
                    <div className="row">
                        <div className="col-sm-6 col-lg-3 mb-2 animatable fadeInUpLong">
                            <WeCard title={"Blockchain Development"} img={blockchain} desc={"Study and transformation of techniques, tools."} link={"/services/blockchain-development"} />
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-2 animatable fadeInUpLong animationDelay200">
                            <WeCard title={"Smart Contracts"} img={blockchain} desc={"Study and transformation of techniques, tools."} link={"/services/smartcontract"} />
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-2 animatable fadeInUpLong animationDelay400">
                            <WeCard title={"Web Apps"} img={blockchain} desc={"Study and transformation of techniques, tools."} link={"/services/webapp"} />
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-2 animatable fadeInUpLong animationDelay600">
                            <WeCard title={"Blockchain Consulting"} img={blockchain} desc={"Study and transformation of techniques, tools."} />
                        </div>
                    </div>
                    <div className="btnRow">
                        <Link to={"/services"} className="themeBtnOutline text-center me-sm-4" >Explore More </Link>
                        <button className="themeBtn calendlySchedule" >Get In Touch</button>
                    </div>
                </div>

            </section> */}


            {/* our project */}
            <div className="pt-5" id="ourProject">
                <section className='ourProject' >
                    <div className="effect"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h1 className='secHeading'>Our Projects</h1>
                            </div>
                            <div className="col-md-6">
                                <p className="desc animatable fadeInUpLong">Science or knowledge put into practical use to solve problems or invent useful tools. Science or knowledge put into practical use to solve problems or invent useful tools.</p>
                            </div>
                        </div>
                        <div className="mt-3 sliderMain">
                            <Slider {...settings}>

                                <div className="p-2">
                                    <div className="projectImg animatable fadeInUpLong">
                                        <img src={bitcoinpk} alt="" />
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="projectImg animatable fadeInUpLong">
                                        <img src={blockpix} alt="" />
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="projectImg animatable fadeInUpLong">
                                        <img src={certifypk} alt="" />
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="projectImg animatable fadeInUpLong">
                                        <img src={daologo} alt="" />
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="projectImg animatable fadeInUpLong">
                                        <img src={empireToken} alt="" />
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="projectImg animatable fadeInUpLong">
                                        <img src={FortKnoxster} alt="" />
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="projectImg animatable fadeInUpLong">
                                        <img src={motor} alt="" />
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="projectImg animatable fadeInUpLong">
                                        <img src={passiveIncome} alt="" />
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="projectImg animatable fadeInUpLong">
                                        <img src={planet} alt="" />
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="projectImg animatable fadeInUpLong">
                                        <img src={roburna} alt="" />
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="projectImg animatable fadeInUpLong">
                                        <img src={shiba} alt="" />
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="projectImg animatable fadeInUpLong">
                                        <img src={studio} alt="" />
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="projectImg animatable fadeInUpLong">
                                        <img src={trustVibe} alt="" />
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </section>
            </div>

            {/* team section */}
            <section className='teamSec' id="about" >
                <div className="container pr z-1">
                    <div className="row ai-center">
                        <div className="col-lg-6 order-2 order-lg-1 ">
                            <div className='imgMain'>
                                <div className='col1'>
                                    <TeamMember activeNo={activeNo} setActiveNo={setActiveNo} ind={0} image={teamData[0].profile} animation="animationDelay600" />
                                    <TeamMember activeNo={activeNo} setActiveNo={setActiveNo} ind={3} image={teamData[3].profile} />
                                </div>
                                <div className='col2'>
                                    <TeamMember activeNo={activeNo} setActiveNo={setActiveNo} ind={1} image={teamData[1].profile} />
                                    <TeamMember activeNo={activeNo} setActiveNo={setActiveNo} ind={4} image={teamData[4].profile} animation="animationDelay400" />
                                </div>
                                <div className='col1'>
                                    <TeamMember activeNo={activeNo} setActiveNo={setActiveNo} ind={2} image={teamData[2].profile} />
                                    <TeamMember activeNo={activeNo} setActiveNo={setActiveNo} ind={5} image={teamData[5].profile} animation="animationDelay400" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className='content'>
                                <h2>Our Team</h2>
                                <h4 className="name">{activeTeam?.name}</h4>
                                <h4 className="desig">{activeTeam?.designation}</h4>
                                <p>{activeTeam?.description}</p>
                                <div className="socialMain">
                                    <a href={activeTeam?.linkedin} target='_blank'><i class="fab fa-linkedin-in"></i></a>
                                    <a href={activeTeam?.twitter} target='_blank'><i class="fab fa-twitter"></i></a>
                                </div>
                                <div className="arrowMain">
                                    <img className={activeNo == 0 ? 'opacity0-5' : ""} onClick={() => handleArrowLeft()} src={arrowLeft} alt="" />
                                    <div className="circleM">
                                        {teamData.map((item, key) => (
                                            <div key={key} className={item.name == activeTeam?.name ? "active Circle" : "Circle"} onClick={() => setActiveNo(key)} ></div>
                                        ))}
                                    </div>
                                    <img className={teamData.length - 1 == activeNo ? 'opacity0-5' : ""} onClick={() => handleArrowRight()} src={arrowRight} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialSec />

            {/* connect section */}
            <GetConnectWithUs />

            <Footer />


        </>
    )
}

const WeCard = ({ title, img, desc, link }) => {
    return (
        <div className="weCard">
            <div className="imgDiv">
                <img className='img-fluid' src={img} alt="" />
            </div>
            <div>
                <div className="title">{title}</div>
                <div className="desc">{desc}</div>
                <Link to={link} className="more">Learn More</Link>
            </div>
        </div>
    )
}

const SpecialCard = ({ img, title, desc }) => {
    return (
        <div className="specialCard">
            <div className="sImgRow">
                <img src={img} alt="" />
            </div>
            <div className="title">{title}</div>
            <div className="desc">{desc}</div>
            <div className="desc2">
                <a href="#">Learn More</a>
            </div>
            {/* <button className="demoBtn calendlySchedule"></button> */}
        </div>
    )
}

const SpecialServiceCard = ({ img, title, desc }) => {
    return (
        <div className="specialCard2">
            <div className="sImgRow">
                <img src={img} alt="" />
                <div className="title">{title}</div>
            </div>
            <div className="desc">{desc}</div>
            <div className="desc2">
                <a href="#">Get a demo</a>
            </div>
            {/* <button className="demoBtn calendlySchedule"></button> */}
        </div>
    )
}

const TeamMember = ({ name, designation, image, animation, activeNo, setActiveNo, ind }) => {
    return (
        <div onClick={() => setActiveNo(ind)} className={teamData[activeNo]?.profile == image ? `active imgDiv` : `imgDiv fadeInUp`} >
            <img src={image} alt="team_member" width="100%" height="100%" />
            {/* <div class="content1">
        <h3>{name}</h3>
        <p>{designation}</p>
        <div>
          <a href={linkedIn} target='_blank' rel="noopener noreferrer"><i class="fab fa-linkedin"></i></a>
        </div>
      </div> */}
        </div>
    )
}

const teamData = [
    {
        name: "Ibtisam Ahmad",
        designation: "Cheif Operating Officer",
        linkedin: "https://www.linkedin.com/in/ibtisam-ahmed-42447211a/",
        twitter: "https://www.linkedin.com/in/ibtisam-ahmed-42447211a/",
        description: " “We are working with the dappomatics team for quite a while now and never had any problems. Can't expect anything more from a blockchain development company. One of the best we have come across” ",
        profile: ibtisam,
    },
    {
        name: "Ammar Khalid",
        designation: "Tech Lead",
        linkedin: "https://www.linkedin.com/in/ammarkhalid123/",
        twitter: "https://www.linkedin.com/in/ammarkhalid123/",
        description: " “We are working with the dappomatics team for quite a while now and never had any problems. Can't expect anything more from a blockchain development company. One of the best we have come across” ",
        profile: ammar,
    },
    {
        name: "Affan Khan",
        designation: "VP Engineering",
        linkedin: "https://www.linkedin.com/in/web3-engineer/",
        twitter: "https://www.linkedin.com/in/web3-engineer/",
        description: " “We are working with the dappomatics team for quite a while now and never had any problems. Can't expect anything more from a blockchain development company. One of the best we have come across” ",
        profile: affan,
    },
    {
        name: "Ahsan Iqbal",
        designation: "Full Stack Developer",
        linkedin: "https://www.linkedin.com/in/ahsan-iqbal-b27212194/",
        description: " “We are working with the dappomatics team for quite a while now and never had any problems. Can't expect anything more from a blockchain development company. One of the best we have come across” ",
        profile: ahsan,
    },
    {
        name: "Mustafa Ahmed",
        designation: "Full Stack Developer",
        twitter: "https://twitter.com/mustafaalvi21",
        linkedin: "https://www.linkedin.com/in/muhammadmustafa-thedev/",
        description: " “We are working with the dappomatics team for quite a while now and never had any problems. Can't expect anything more from a blockchain development company. One of the best we have come across” ",
        profile: mustafa,
    },
    {
        name: "Fazal Ullah",
        designation: "Business Development Head",
        linkedin: "https://www.linkedin.com/in/web3-engineer/",
        twitter: "https://www.linkedin.com/in/web3-engineer/",
        description: " “We are working with the dappomatics team for quite a while now and never had any problems. Can't expect anything more from a blockchain development company. One of the best we have come across” ",
        profile: fazal,
    },
];