import React from "react";
import logo from "../assets/images/logo.svg";
import twitter from "../assets/images/twitterBlack.svg";
import discord from "../assets/images/discordBlack.svg";
import insta from "../assets/images/instaBlack.svg";
import arrowUp from "../assets/images/arrowUp.svg";

export const Footer = () => {
  function scrollToTop() {
    window.scrollTo(0, 0);
  }
  return (
    <footer>
      <div className="container ">
        <div className="footerRow">
          <div
            className="arrowUp"
            onClick={scrollToTop}
            style={{ backgroundColor: "#2979FF" }}
          >
            <img src={arrowUp} alt="" />
          </div>
          <div className="col1">
            <div className="logoDiv">
              <img src={logo} alt="" />
            </div>
            <p></p>
            <a href="mailto:contact@dappomatics.de" className="mail">
              contact@dappomatics.de
            </a>
            <div className="socialMain">
              <a
                href="https://twitter.com/dappomatics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/dappomatics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in mt-1"></i>
              </a>
              <a
                href="https://www.instagram.com/dappomatics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={insta} alt="" />
              </a>
            </div>
          </div>
          <div className="lg:w-3/4 flex flex-wrap items-start max-lg:justify-start justify-end">
            <div className="grid lg:grid-cols-2 grid-cols-1 sm:gap-10 sm:px-4 max-sm:px-2">
              <div>
                <h6 className="mb-2">Our Services</h6>
                <ul>
                  <li>
                    <a href="/services/p2p-exchange-development">
                      P2P Exchange Development
                    </a>
                  </li>
                  <li>
                    <a href="/services/crypto-payment-development">
                      Crypto Payment Development
                    </a>
                  </li>
                  <li>
                    <a href="/services/EVM-compaitable-blockchain-solutions">
                      EVM Compaitable Blockchain Solutions
                    </a>
                  </li>
                  <li>
                    <a href="/services/decentralized-exchange-development">
                      Decentralized Exchange Development
                    </a>
                  </li>
                  <li>
                    <a href="/services/blockchain-development">
                      Blockchain Development
                    </a>
                  </li>
                  <li>
                    <a href="/services/smartcontract">Smart Contracts</a>
                  </li>
                  <li>
                    <a href="/services/NFT-development">NFT Development</a>
                  </li>
                  <li>
                    <a href="#">Blockchain Consulting</a>
                  </li>
                  <li>
                    <a href="/services/webapp">Web Apps</a>
                  </li>
                  <li>
                    <a href="#">Mobile Apps</a>
                  </li>
                  <li>
                    <a href="#">UI/UX Design</a>
                  </li>
                </ul>
              </div>
            <div className="flex flex-row max-sm:mt-5 items-start justify-center ">
              <div className="col2">
                <h6>Usefull Links</h6>
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="#">Blog </a>
                  </li>
                  <li>
                    <a href="#">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="col3">
                <h6>Company</h6>
                <ul>
                  <li>
                    <a href="#">Company</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#">Blog </a>
                  </li>
                  <li>
                    <a href="#">Terms of use</a>
                  </li>
                </ul>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
