import { useEffect, useState } from 'react';
import logo from './logo.svg';
import { Modal, Button } from "react-bootstrap"

import './App.css';
import './assets/css/animate.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { Home } from './component/home';

import linkedin from './assets/images/linkedin.svg'
import facebook from './assets/images/facebook.svg'
import twitter from './assets/images/twitter.svg'
import insta from './assets/images/insta.svg'
import email from './assets/images/email.svg'
import phone from './assets/images/phone.svg'
import closeIco from './assets/images/closeIco.svg'

import axios from 'axios';
import { Services } from './component/servicePage.js/services';
import { SmartContract } from './component/servicePage.js/SmartContract';
import { WebApp } from './component/servicePage.js/webApp';
import { BlockChainConsulting } from './component/servicePage.js/blockchain';
import { NFTDevelopment } from './component/servicePage.js/nftDevelopment';
import { DecentralizedExchangeDevelopment } from './component/servicePage.js/decentralizedExchangeDevelopment';
import { P2PExchangeDevelopment } from './component/servicePage.js/p2pExchangeDevelopment';
import CryptoPaymentDevelopment from './component/servicePage.js/cryptoPaymentDevelopment'
import EVMcompaitableBlockchainSolutions from './component/servicePage.js/EVMcompaitableBlockchainSolutions';


function App() {
  const [show, setShow] = useState(false);
  const [showMeeting, setShowMeeting] = useState(false);
  const [connectModalText, setConnectModalText] = useState("");
  const [contactForm, setContactForm] = useState({   
    name:"",
    email:"",
    phone:"",
    subject:"",
    message:"",
});

  const handleClose = () => setShow(false);
  const handleShow = (text) => {
      setShow(true)
      setConnectModalText(text)
  }
  const handleCloseMeeting = () => setShowMeeting(false);
  const handleShowMeeting = (text) => {
      setShowMeeting(true)
  }


  const handleContactF =(e) => {
    setContactForm({...contactForm,[e.target.name]:e.target.value})
  }

  const submitContactForm =(e) => {
    e.preventDefault();
    console.log(contactForm);
    var valid = /^!*(\d!*){10,}$/;
    var validM = valid.test(contactForm.phone)
    if(contactForm.phone && contactForm.phone.includes(' ') ) return alert("Spaces are not allowed in phone number");
    if(contactForm.phone && (!validM || contactForm.phone.length < 10)) return alert("Phone must contain atleast 10 digits");
    
    if(!contactForm.email){
      // return addToast('email is required', { appearance: 'warning',autoDismiss: true, });
      return alert('email is required');
    }
    if(!contactForm.message){
      // return addToast('message is required', { appearance: 'warning',autoDismiss: true, });
      return alert('message is required');
    }
    const response = axios.post(`${process.env.REACT_APP_BASEURL}/contact-us-mailer`, contactForm)
    .then((res)=>{
      console.log(res);
        // addToast('Thanks, we will reach you soon.', { appearance: 'warning',autoDismiss: true, });
        alert('Thanks, we will reach you soon.');
        handleClose(false)
        setContactForm({   
            name:"",
            email:"",
            phone:"",
            subject:"",
            message:"",
        });
    })
    .catch((err)=>{
        console.log(err);
        // addToast('Something went wrong', { appearance: 'error',autoDismiss: true, });
        alert('Something went wrong');
        // handleClose(false)
    })
  }

  return (
    <div className="App">
       <Router>
         <Switch>
            <Route exact path="/">
              <Home handleShow={handleShow} />
            </Route>
            <Route exact path="/services" >
              <Services handleShow={handleShow} />
            </Route>
            <Route exact path="/services/smartcontract" >
              <SmartContract handleShow={handleShow} />
            </Route>
            <Route exact path="/services/webapp" >
              <WebApp handleShow={handleShow} />
            </Route>
            <Route exact path="/services/blockchain-development" >
              <BlockChainConsulting handleShow={handleShow} />
            </Route>
            <Route exact path="/services/NFT-development" >
              <NFTDevelopment handleShow={handleShow} />
            </Route>
            <Route exact path="/services/decentralized-exchange-development" >
              <DecentralizedExchangeDevelopment handleShow={handleShow} />
            </Route>
            <Route exact path="/services/p2p-exchange-development" >
              <P2PExchangeDevelopment handleShow={handleShow} />
            </Route>
            <Route exact path="/services/crypto-payment-development" >
              <CryptoPaymentDevelopment handleShow={handleShow} />
            </Route>
            <Route exact path="/services/EVM-compaitable-blockchain-solutions" >
              <EVMcompaitableBlockchainSolutions handleShow={handleShow} />
            </Route>
         </Switch>
       </Router>

      <Modal show={show} onHide={handleClose} className='contactModal' centered={true}>
        <Modal.Body>
          <div className="mainContent">
            <img src={closeIco} className="closeIco" alt="" onClick={handleClose} />
            {/* <i class="fa fa-times closeIco" ></i> */}
            <div className="row mx-0">
              <div className="col-md-5 col1">
                <div className=''>
                  <h1 >{connectModalText}</h1>
                  <p className="contact">
                  You’ve any question? <br />
                  </p>
                  <div className="feeFree">feel free to contact us.</div>
                  
                  <div className="socialMain">
                    <a href="https://www.linkedin.com/company/dappomatics" target="_blank" ><img src={linkedin} alt="" /> </a>
                    <a href="https://www.facebook.com/dappomatics" target="_blank" > <img src={facebook} alt="" /></a>
                    <a href="https://twitter.com/dappomatics" target="_blank" > <img src={twitter} alt="" /></a>
                    <a href="https://www.instagram.com/dappomatics" target="_blank" > <img src={insta} alt="" /></a>
                  </div>
                  <p className='callUs'>Call us for imiditate support on these numbers</p>
                  <div className='contentDiv'>
                    <div className="imgDiv">
                      <img src={phone} alt="phone" />
                    </div>
                    <div className='contactDiv'>
                      <a href="tel:+ 92 335 2491559"  >+92 335 2491559</a>
                    </div>
                  </div>
                  <div className='contentDiv'>
                    <div className="imgDiv">
                      <img src={phone} alt="phone" />
                    </div>
                    <div className='contactDiv'>
                      <a href="tel:+49 1573 6735515"  >+49 1573 6735515</a>
                    </div>
                  </div>
                  <div className='contentDiv'>
                    <div className="imgDiv">
                      <img src={phone} alt="phone" />
                    </div>
                    <div className='contactDiv'>
                      <a href="tel:+49 1577 5568031"  >+49 1577 5568031</a>
                    </div>
                  </div>
                  <div className='contentDiv'>
                    <div className="imgDiv">
                      <img src={email} alt="email" />
                    </div>
                    <div className='contactDiv'>
                      {/* <p>Send us email for any kind of inquiry</p> */}
                      <a href="mailTo:contact@dappomatics.com" target="_blank" >contact@dappomatics.com</a>
                    </div>
                  </div>
                
                </div>
              </div>
              <div className="col-md-7 col2">
              <form onSubmit={(e) => submitContactForm(e)}>
                <div className="form-group">
                  {/* <label htmlFor="exampleInputPassword1">Password</label> */}
                  <input onChange={(e)=>handleContactF(e)} name="name" type="text" className="form-control"  placeholder="Name*" />
                </div>
                <div className="form-group">
                  {/* <label htmlFor="exampleInputEmail1">Email address</label> */}
                  <input onChange={(e)=>handleContactF(e)} name="email" type="email" className="form-control"  aria-describedby="emailHelp" placeholder="Your email address*" />
                </div>
                <div className='d-flex'>
                  <div className="form-group me-3 w-100">
                    <input onChange={(e)=>handleContactF(e)} name="phone" type="text" className="form-control" placeholder="Skype or Phone" />
                  </div>
                  <div className="form-group w-100">
                    <input onChange={(e)=>handleContactF(e)} name="subject" type="text" className="form-control" placeholder="Subject*" />
                  </div>
                </div>
                <div className="form-group">
                  <textarea onChange={(e)=>handleContactF(e)} name="message" type="text" className="form-control" id="exampleInputPassword1" placeholder="Write message here*" rows={4} />
                </div>
              
                <button type="submit" className="themeBtn py-2 px-3 ">Send Message</button>
              </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}



export default App;
