import React, { useEffect, useState } from 'react'
import $ from "jquery";

export const ProjectCounter = () => {
    const [counter1,setCounter]= useState(1)
    var [project, setproject] = useState(0)
    var [customer, setcustomer] = useState(0)
    var [developer, setdeveloper] = useState(0)
    var [clientCount, setClientCount] = useState(0)
    
    function CounterAnimate() {

        // Function which adds the 'animated' class to any '.animatable' in view
        var doAnimations = function () {
            "use strict";

            // Calc current offset and get all animatables
            var offset = $(window).scrollTop() + $(window).height(),
                $counterSec = $('.counterSec');

            // Unbind scroll handler if we have no counterSec
            if ($counterSec.length == 0) {
                $(window).off('scroll', doAnimations);
            }

            // Check all counterSec and animate them if necessary
            $counterSec.each(function (i) {
                var $counterS = $(this);
                if (($counterS.offset().top + $counterS.height() - 20) < offset) {
                    $counterS.removeClass('counterSec').addClass('counterOn');
                    // console.log(offset);
                    handleCounter()
                    handleCustomersData()
                }

            });

        };

        // Hook doAnimations on scroll, and trigger a scroll
        $(window).on('scroll', doAnimations);
        $(window).trigger('scroll');

    }


    const handleCounter = () => {


        var startproject = project
        var endproject = 42;
        var startCustomer = customer
        var endCustomer = 7;
        var startDeveloper = developer
        var endDeveloper = 46;
        var startClient = clientCount
        var endClient = 6;

        var projectCounting = setInterval(function () {
            if (startproject < endproject) {
                startproject++
            } else {
                clearInterval(projectCounting)
            }
            // console.log(startproject);
            setproject(startproject)
        }, 10);

        var developerCounting = setInterval(function () {
            if (startDeveloper < endDeveloper) {
                startDeveloper++
            } else {
                clearInterval(developerCounting)
            }
            // console.log(startDeveloper);
            setdeveloper(startDeveloper)
        }, 100);

        var clientCounting = setInterval(function () {
            if (startClient < endClient) {
                startClient++
            } else {
                clearInterval(clientCounting)
            }
            // console.log(startClient);
            setClientCount(startClient)
        }, 100);
    }

    const handleCustomersData = () => {
        var startCustomer = customer
        var endCustomer = 7;

        var customerCounting = setInterval(function () {
            if (startCustomer < endCustomer) {
                startCustomer++
            } else {
                clearInterval(customerCounting)
            }
            // console.log(startCustomer);
            setcustomer(startCustomer)
        }, 10);
    }

    useEffect(() => {
        CounterAnimate()
    }, [])
    

  return (
    <section className='projectCounter1' >
        <div className='container'>
            <div className="counterMain">
                <div className="countCard animatable fadeInUpLong  counterSec" >
                    <div className="contentCard">
                        <h3>{project}+</h3>
                        <p>Finished Projects</p>
                    </div>
                </div>
                <div className="countCard animatable fadeInUpLong animationDelay200  counterSec">
                    <div className="contentCard">
                        <h3>{customer}</h3>
                        <p>Ongoing Projects</p>
                    </div>
                </div>
                <div className="countCard animatable fadeInUpLong animationDelay400 ">
                    <div className="contentCard">
                        <h3>{clientCount}</h3>
                        <p>Happy client</p>
                    </div>
                </div>
                <div className="countCard animatable fadeInUpLong animationDelay600 ">
                    <div className="contentCard">
                        <h3>{developer}</h3>
                        <p>Years of Experience</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
