import React from 'react'
import { useEffect } from 'react'
import { Navbar } from '../navbar'
import "./service.css"
import serviceBanner from "../../assets/images/webAppBanner.png"
import unleashWeb from "../../assets/images/unleashWeb.png"
import customizeWeb from "../../assets/images/customizeWeb.png"
import secureWeb from "../../assets/images/secureWeb.png"
import blockchainDev from "../../assets/images/blockchainDev.png"
import enterpriseSol from "../../assets/images/enterpriseSol.png"
import mobileUi from "../../assets/images/mobileUi.png"
import { Footer } from '../footer'
import { animate } from '../../assets/js/animate'
import { GetConnectWithUs } from '../connectWithUs'

export const WebApp = ({handleShow}) => {
  useEffect(() => {
    animate()
}, [])

  return (
    <>
        <Navbar handleShow={handleShow} />
        <div className="servicePage">
        <section className='secOne' id='sec1'>
                <div className="container">
                    <div className="row ai-center">
                        <div className="col-lg-6">
                            <h1>Web Application Development</h1>
                            <p>Empowering Businesses with Customized, Secure, and Scalable Software Solutions</p>
                            {/* <div className="btnMain">
                                <button className="themeBtn" onClick={()=>handleShow("Let's Connect")}>Let's Talk </button>
                            </div> */}
                        </div>
                        <div className="col-lg-6">
                            <div className="imgDiv">
                                <img className='img-fluid' src={serviceBanner} alt="DEFI" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='sevicesWeOffer2 text-center'>
              <div className="container">
                <p>Welcome to Dappomatics' web application development page, where we offer a wide range of custom software solutions to cater to the specific needs of businesses of all sizes. Our team of expert developers is committed to providing state-of-the-art technologies and frameworks to ensure that our clients receive high-quality web applications with outstanding functionality and user-friendly interfaces.</p>
              </div>
            </section>

            <section className='serviceSec'>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1">
                    <div className="serviceCard">
                      <h2 className='animatable fadeInUpLong '>Unleashing the Power of Technology for Your Business</h2>
                      <p className='animatable fadeInUpLong animationDelay200 '>Dappomatics is a leading software development company that offers cutting-edge web application solutions to our clients. Our team takes pride in being at the forefront of the latest and greatest technologies, which ensure that our clients enjoy a competitive edge over others. Whether you are looking to develop a new web application, implement an existing one, or migrate your existing web application, our team is equipped to provide the right solutions for your needs.</p>
                    </div>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2">
                    <div className="imgDiv">
                      <img className='img-fluid' src={unleashWeb} alt="" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="imgDiv">
                      <img className='img-fluid' src={customizeWeb} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="serviceCard">
                      <h2 className='animatable fadeInUpLong '>Customized Solutions to Meet Your Business Needs</h2>
                      <p className='animatable fadeInUpLong animationDelay200 '>At Dappomatics, we believe that customized software solutions are the key to unlocking the full potential of your business. Our team works closely with clients to understand their unique needs, objectives, and requirements, and provide software solutions that are tailored to their business.</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1">
                    <div className="serviceCard">
                      <h2 className='animatable fadeInUpLong '>Secure and Scalable Web Applications</h2>
                      <p className='animatable fadeInUpLong animationDelay200 '>Data breaches and cyber threats are major concerns for businesses today, which is why we give the utmost importance to security in our software development processes. Our team follows industry-standard security protocols and procedures to ensure that our clients' critical data is secure at all times. Scalability is another crucial aspect that we consider while designing web applications. Our solutions can handle the growing demands of your business, ensuring that they can support your business' growth over time.</p>
                      <button className="themeBtn animatable fadeInUpLong animationDelay200 ">Learn More</button>
                    </div>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2">
                    <div className="imgDiv">
                      <img className='img-fluid' src={secureWeb} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className='concSec'>
              <div className="container">
                <p>Expertise in Developing Web Applications with Modern Technologies and Frameworks Our developers have expertise in a wide range of modern technologies and frameworks, including React, Angular, Vue.js, Ruby on Rails, and Django. This enables us to provide web application solutions that cater to your specific needs, while ensuring that they are compatible with the latest and most popular technologies.</p>
              </div>
            </section>
             {/* connect section */}
            <GetConnectWithUs />
            <Footer />
        </div>
    </>
  )
}
