import React from 'react'
import { useEffect } from 'react'
import { Navbar } from '../navbar'
import "./service.css"
import { Footer } from '../footer'
import { animate } from '../../assets/js/animate'
import { GetConnectWithUs } from '../connectWithUs'
import serviceBanner from "../../assets/images/smartContractBanner.png"
import webApp from "../../assets/images/webApp.png"
import mobileApp from "../../assets/images/mobileApp.png"
import blockchainDev from "../../assets/images/blockchainDev.png"
import blockChain from "../../assets/images/blockchainService.svg"
import smartContractService from "../../assets/images/smartContractService.svg"
import inovatoveSol from "../../assets/images/inovatoveSol.svg"
import security from "../../assets/images/security.svg"
import costEffective from "../../assets/images/costEffective.svg"

export const SmartContract = ({handleShow}) => {
  useEffect(() => {
    animate()
}, [])
  return (
    <>
        <Navbar handleShow={handleShow} />
        <div className="servicePage">
            <section className='secOne' id='sec1'>
                <div className="container">
                    <div className="row ai-center">
                        <div className="col-lg-6">
                            <h1 className='animatable fadeInUpLong'>Smart Contract Development Services</h1>
                            <p className='animatable fadeInUpLong animationDelay200 '>Dappomatics is a leading blockchain development company with extensive 
experience in developing smart contracts across major platforms like 
Ethereum. Our smart contract developers help companies build the best 
smart contract solutions with the latest technologies. Our expert developers 
are well-versed in a variety of smart contract development tools and 
protocols, and can develop custom solutions tailored to your business.</p>
                            <div className="btnMain">
                                <button className="themeBtn" onClick={()=>handleShow("Let's Connect")}>Let's Talk </button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="imgDiv">
                                <img className='img-fluid' src={serviceBanner} alt="DEFI" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='sevicesWeOffer2 text-center'>
              <div className="container">
                <h1 className='mb-4'>Services We Are Offering</h1>
                <p>Smart contracts offer a wide range of benefits for businesses, including greater efficiency, cost-savings, and enhanced security. With smart contracts, businesses can automate manual processes, streamline operations, and reduce the risk of fraud and other issues. Smart contracts also enable businesses to take advantage of the transparency of the blockchain, providing greater accuracy and reliability for data.</p>
              </div>
            </section>

            <section className='serviceSec'>
              <div className="container">
                <div className="head2 animatable fadeInUpLong ">Why Choose Dappomatics?</div>
                <div className="desc animatable fadeInUpLong animationDelay100 ">There are several compelling reasons why choosing Dappomatics for smart contract development is a shrewd business decision. Some of the critical points to consider include:</div>
                <div className="row jc-center">
                  <div className="col-lg-6 mb-4">
                    <ServiceCard image={blockChain} heading={"Extensive Blockchain Expertise"} text={"Dappomatics is a leading blockchain development company with years of experience in developing smart contracts on various platforms. Our team's expertise in blockchain technology allows us to provide best smart contract solutions."} />
                  </div>
                  <div className="col-lg-6 mb-4">
                    <ServiceCard image={smartContractService} heading={"Superior Smart Contract Development"} text={"Our smart contract developers utilize cutting-edge technology and best practices to create robust, secure, and efficient smart contracts for our clients. This ensures that every customer gets a customized solution that works seamlessly and efficiently."} />
                  </div>
                  <div className="col-lg-6 mb-4">
                    <ServiceCard image={inovatoveSol} heading={"Innovative Solutions"} text={"Dappomatics is renowned for its innovative approach to smart contract development. We continuously update our service offerings with the latest technologies and trends so that we can provide our clients with solutions that are ahead of their time."} />
                  </div>
                  <div className="col-lg-6 mb-4">
                    <ServiceCard image={security} heading={"Security and Transparency"} text={"Our smart contract development process has been designed with security and transparency in mind. Our developers perform rigorous testing and validation of the 12qsmart contracts to ensure that they are secure and free of errors."} />
                  </div>
                  <div className="col-lg-6 mb-4">
                    <ServiceCard image={costEffective} heading={"Cost-Effective"} text={"Our smart contract development services are highly cost-effective, allowing businesses of all sizes to access high-quality, customized solutions at an affordable price."} />
                  </div>
                </div>
               
              </div>
            </section>
            <section className='concSec'>
              <div className="container">
                <p>In short, choosing Dappomatics for smart contract development is a sound business decision that offers a superior solution with advanced technology, innovation, security, and cost-effectiveness.</p>
              </div>
            </section>
             {/* connect section */}
            <GetConnectWithUs />
            <Footer />
        </div>
    </>
  )
}

const ServiceCard = ({image,heading,text}) => {
    return(
        <div className="serviceCard text-center mw-100">
            <div className="imgDiv">
                <img className='img-fluid' src={image} alt="" />
            </div>
          <h4 className='animatable fadeInUpLong '>{heading}</h4>
          <p className='animatable fadeInUpLong animationDelay200 '>{text}</p>
        </div>
    )
} 