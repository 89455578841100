import React from 'react'
import { useEffect } from 'react'
import { Navbar } from '../navbar'
import "./service.css"
import ExchangeBanner from "../../assets/images/p2pExchangeDevelopment/banner.png"
import Group1 from "../../assets/images/p2pExchangeDevelopment/group-1.png"
import Group2 from "../../assets/images/p2pExchangeDevelopment/group-2.png"
import Group3 from "../../assets/images/p2pExchangeDevelopment/group-3.png"
import Group4 from "../../assets/images/p2pExchangeDevelopment/group-4.png"

import { Footer } from '../footer'
import { animate } from '../../assets/js/animate'
import { GetConnectWithUs } from '../connectWithUs'
import { ProjectCounter } from './projectCounterSec'

export const P2PExchangeDevelopment = ({ handleShow }) => {
    useEffect(() => {
        animate()
    }, [])

    return (
        <>
            <Navbar handleShow={handleShow} />
            <div className="servicePage">
                <section className='secOne' id='sec1'>
                    <div className="container">
                        <div className="row ai-center">
                            <div className="col-lg-6">
                                <h1>P2P <br /> Exchange <br /> Development</h1>
                                <p>Welcome to Dappomatics - Your Partner for Custom P2P Crypto Exchange Development.</p>
                            </div>
                            <div className="col-lg-6">
                                <div className="imgDiv">
                                    <img className='img-fluid' src={ExchangeBanner} alt="DEFI" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




                <section className='projectCounter1' >
                    <div className='container'>
                        <div className="counterMain">
                            <div className="countCard w-100">
                                <p>
                                    At Dappomatics, we specialize in building custom P2P crypto exchanges tailored to meet your specific business needs. With years of
                                    experience, technical expertise, and a deep understanding of the cryptocurrency market and its challenges, we are your go-to
                                    solution for building a robust, secure, and user-friendly exchange that reflects your brand’s values.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>



                <section className='serviceSec'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="serviceCard">
                                    <h2 className='animatable fadeInUpLong '>Why Build a Custom Crypto Exchange?</h2>
                                    <p className='animatable fadeInUpLong animationDelay200 '>A custom P2P crypto exchange offers several advantages over off-the-shelf solutions. Most importantly, it allows you to create a platform that aligns with your unique business goals, vision, and audience’s expectations. By choosing Dappomatics, you gain access to our development team comprised of experienced developers, designers, and cryptocurrency experts who will help you shape every aspect of your exchange, from user experience and security to liquidity and trading volumes.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="imgDiv">
                                    <img className='img-fluid' src={Group1} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="imgDiv">
                                    <img className='img-fluid' src={Group2} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="serviceCard">
                                    <h2 className='animatable fadeInUpLong '>Our Custom P2P Crypto Exchange Development Process?</h2>
                                    <p className='animatable fadeInUpLong animationDelay200 '>At Dappomatics, we follow a clear and comprehensive process to ensure that your exchange is built to perfection. We start by understanding your business needs, your target audience, and your competitors. We analyze the market trends, regulatory requirements, and technological innovations that shape the cryptocurrency industry to craft a unique development plan for you. Next, we design and develop your exchange’s core features, including user authentication, trading engine, order book, wallets, and admin panel. We integrate advanced security measures, such as two-factor authentication, KYC/AML compliance, and DDoS protection, to ensure the utmost safety of your users’ data and funds.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="serviceCard">
                                    <h2 className='animatable fadeInUpLong '>Why Choose Dappomatics for Your Custom P2P Crypto Exchange Development?</h2>
                                    <p className='animatable fadeInUpLong animationDelay200 '>Choosing Dappomatics as your development partner means benefiting from our experience, expertise, and commitment to excellence. We pride ourselves on offering a transparent, reliable, and customer-centric approach, meaning we are here to help you at every step of the way. Our team of developers is skilled in blockchain and cryptocurrency technology, ensuring that your exchange is top-notch in terms of user experience, liquidity, and security. We also have a deep understanding of cryptocurrency exchanges, market trends, and regulations, placing us in the ideal position to help you navigate the complex and ever-changing crypto landscape.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="imgDiv">
                                    <img className='img-fluid' src={Group3} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="imgDiv">
                                    <img className='img-fluid' src={Group4} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="serviceCard">
                                    <h2 className='animatable fadeInUpLong '>Ready to Build Your Custom P2P Crypto Exchange?</h2>
                                    <p className='animatable fadeInUpLong animationDelay200 '>At Dappomatics, we are passionate about building custom P2P crypto exchanges that reflect your brand’s value and meet your business objectives. If you are ready to take the next step in cryptocurrency innovation, we invite you to schedule a consultation with us today. We would be thrilled to help you bring your vision to life and make your exchange a success.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* connect section */}
                <GetConnectWithUs />
                <Footer />
            </div>
        </>
    )
}