import React from 'react'
import { useEffect } from 'react'
import { Navbar } from '../navbar'
import "./service.css"
import nftBanner from "../../assets/images/nftBanner.png"
import nftDev1 from "../../assets/images/nftDev1.png"
import nftDev2 from "../../assets/images/nftDev2.png"
import nftDev3 from "../../assets/images/nftDev3.png"

import { Footer } from '../footer'
import { animate } from '../../assets/js/animate'
import { GetConnectWithUs } from '../connectWithUs'
import { ProjectCounter } from './projectCounterSec'

export const NFTDevelopment = ({handleShow}) => {
  useEffect(() => {
    animate()
}, [])

  return (
    <>
        <Navbar handleShow={handleShow} />
        <div className="servicePage">
        <section className='secOne' id='sec1'>
                <div className="container">
                    <div className="row ai-center">
                        <div className="col-lg-6">
                            <h1>NFT <br /> Market Place Development</h1>
                            <p>Dappomatics is the all-in-one platform for Non-Fungible Tokens (NFTs).</p>
                            {/* <div className="btnMain">
                                <button className="themeBtn" onClick={()=>handleShow("Let's Connect")}>Let's Talk </button>
                            </div> */}
                        </div>
                        <div className="col-lg-6">
                            <div className="imgDiv">
                                <img className='img-fluid' src={nftBanner} alt="DEFI" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ProjectCounter />

            <section className='serviceSec'>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1">
                    <div className="serviceCard">
                      <h2 className='animatable fadeInUpLong '>Tap into the World of NFTs</h2>
                      <p className='animatable fadeInUpLong animationDelay200 '>Dappomatics is the all-in-one platform for Non-Fungible Tokens (NFTs). Our platform provides users with the tools they need to create, manage, and market their own NFTs. Our cutting-edge technology offers all the features and functions you need to unlock your digital potential. With our easy-to-use platform, you can easily and quickly create and manage your own digital assets.</p>
                    </div>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2">
                    <div className="imgDiv">
                      <img className='img-fluid' src={nftDev1} alt="" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="imgDiv">
                      <img className='img-fluid' src={nftDev2} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="serviceCard">
                      <h2 className='animatable fadeInUpLong '>Smart Contracts & Security</h2>
                      <p className='animatable fadeInUpLong animationDelay200 '>Dappomatics offers the most secure and reliable platform for creating and managing NFTs. Our smart contract technology provides users with the highest levels of security and trust. We offer advanced security features to ensure that your digital assets are safe and secure. Our platform is also designed to be user-friendly and intuitive, making it easy for anyone to get started.</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1">
                    <div className="serviceCard">
                      <h2 className='animatable fadeInUpLong '>NFT Marketplace & Transactions</h2>
                      <p className='animatable fadeInUpLong animationDelay200 '>Dappomatics offers an all-in-one marketplace for NFTs. Our platform allows users to buy, sell, and trade their digital assets. We provide a secure and reliable platform for all transactions, making it easy for users to manage their digital assets. We also offer advanced features such as staking and decentralized exchanges, allowing users to easily manage their digital assets in the most secure way possible.</p>
                    </div>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2">
                    <div className="imgDiv">
                      <img className='img-fluid' src={nftDev3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
         
             {/* connect section */}
            <GetConnectWithUs />
            <Footer />
        </div>
    </>
  )
}
